<div class="items-center space-x-3 group" [ngClass]="getWrapperClasses()">
  <div class="items-center justify-center shrink-0" [ngClass]="getButtonClasses()">
    <div class="flex items-center text-left" [ngClass]="getContentClasses()">
      <div class="shrink-0" *ngIf="isIconContainerVisible()" [ngClass]="{ 'pr-1.5': !isRound }">
        <ng-container *ngIf="!loading">
          <lis-icon *ngIf="icon" [name]="icon"></lis-icon>
        </ng-container>
        <div *ngIf="loading" class="size-6 flex items-center justify-center">
          <lis-spinner [colorVariant]="'blue'" [size]="16"></lis-spinner>
        </div>
      </div>
      <div>
        <ng-content></ng-content>
      </div>
      <div class="shrink-0" *ngIf="iconRight" class="pl-1.5">
        <lis-icon [name]="iconRight"></lis-icon>
      </div>
    </div>
  </div>
</div>
