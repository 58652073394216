import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  Optional,
  ViewChild,
} from '@angular/core';
import { getIconSize, neverError } from '@lis-helpers';
import { IconsRegistry } from '@lis-services';
import { LIS_ICON_SIZE_DEFAULT, LisIcon, LisIconSize } from '@lis-types';

@Component({
  selector: 'lis-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  private svgIcon?: SVGElement;
  // @Input() size?: LisIconSize;
  @ViewChild('iconContainer', { static: true })
  iconContainer?: ElementRef<HTMLElement>;

  private _size: LisIconSize = LIS_ICON_SIZE_DEFAULT;

  @Input({ required: true })
  set name(iconName: LisIcon) {
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }

    const svgData = this.iconRegistry.getIcon(iconName);

    if (!svgData) {
      console.warn(`no svg for "${iconName}"`);
      return;
    }

    this._size = this.getIconSize(iconName);
    this.svgIcon = this.svgElementFromString(svgData);

    if (this.iconContainer) {
      this.iconContainer.nativeElement.appendChild(this.svgIcon);
    } else {
      // this.iconContainer is not available yet
      // but after timeout it should be available
      setTimeout(() => {
        if (this.iconContainer && this.svgIcon) {
          this.iconContainer.nativeElement.appendChild(this.svgIcon);
          this.cdRef.markForCheck();
        }
      });
    }
  }

  constructor(
    private element: ElementRef,
    private iconRegistry: IconsRegistry,
    private cdRef: ChangeDetectorRef,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Optional() @Inject(DOCUMENT) private document: any
  ) {}

  private svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    return (
      div.querySelector('svg') ||
      this.document.createElementNS('http://www.w3.org/2000/svg', 'path')
    );
  }
  public getIconSizeClasses(): string[] {
    const classes = [];
    switch (this._size) {
      case 18:
        classes.push('w-4.5', 'h-4.5');
        break;
      case 20:
        classes.push('w-5', 'h-5');
        break;
      case 24:
        classes.push('w-6', 'h-6');
        break;
      case 40:
        classes.push('w-10', 'h-10');
        break;
      default:
        throw neverError(this._size);
    }

    return classes;
  }

  private getIconSize(name: LisIcon): LisIconSize {
    return getIconSize(name);
  }
}
