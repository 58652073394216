import { Component, HostBinding, Input } from '@angular/core';
import {
  LisButtonSize,
  LisButtonVariant,
  LisIcon,
  LisIconSize,
} from '@lis-types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[lis-button], a[lis-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() variant: LisButtonVariant = 'flat';
  @Input() isRound = false;
  @Input() icon?: LisIcon;
  @Input() iconRight?: LisIcon;
  @Input() iconSize: LisIconSize = 24;
  @Input() size: LisButtonSize = 'M';
  @Input() loading = false;

  @HostBinding() @Input() isDisabled = false;
  @HostBinding('class.is-elastic') @Input() elastic = false;
  @HostBinding('class.is-full') @Input() full = false;
  @HostBinding('attr.disabled') get disabled(): boolean | null {
    return this.isDisabled || this.loading || null;
  }

  @HostBinding('class.lis-button') get lisButton(): boolean {
    return true;
  }

  public getWrapperClasses(): string[] {
    const classes = [];

    if (this.disabled) {
      classes.push('cursor-not-allowed');
    }

    if (this.elastic) {
      classes.push('flex', 'sm:inline-flex');
    } else if (this.full) {
      classes.push('w-full');
    } else {
      classes.push('flex');
    }

    return classes;
  }

  public getButtonClasses(): string[] {
    const classes = ['transition-all', 'duration-200', 'ease-in-out'];

    if (this.elastic) {
      classes.push('flex', 'w-full', 'sm:inline-flex');
    } else if (this.full) {
      classes.push('flex');
    } else {
      classes.push('inline-flex');
    }

    classes.push('leading-none', 'border');
    if (this.isRound) {
      classes.push('rounded-full');
    } else {
      classes.push('rounded');
    }
    if (!this.isRound) {
      if (this.size === 'M') {
        classes.push('h-8.5');
      } else {
        classes.push('h-11');
      }
    }

    if (this.isRound) {
      if (this.size === 'M') {
        classes.push('p-1');
      } else {
        classes.push('p-2');
      }
    } else {
      if (this.variant !== 'ghost') {
        if (this.size === 'M') {
          classes.push('px-2.5');
        } else {
          classes.push('px-3');
        }
      }
    }

    switch (this.variant) {
      case 'flat':
        if (this.disabled) {
          classes.push(
            'bg-button-flat-background-disabled',
            'text-button-flat-foreground-disabled',
            'border-button-flat-background-disabled'
          );
        } else {
          classes.push(
            'text-button-flat-foreground',
            'bg-button-flat-background',
            'border-button-flat-background',
            'group-hover:bg-button-flat-background-hover',
            'group-hover:border-button-flat-background-hover'
          );
        }
        break;

      case 'outline':
        if (this.disabled) {
          classes.push(
            'text-button-outline-border-disabled',
            'border-button-outline-border-disabled'
          );
        } else {
          classes.push(
            'text-button-outline-foreground',
            'bg-button-outline-background',
            'border-button-outline-foreground',
            'group-hover:text-button-outline-foreground-hover',
            'group-hover:bg-button-outline-background-hover',
            'group-hover:border-button-outline-foreground-hover'
          );
        }
        break;

      case 'ghost':
        if (this.disabled) {
          classes.push(
            'text-button-ghost-foreground-disabled',
            'bg-button-ghost-background-disabled'
          );
        } else {
          classes.push(
            'text-button-ghost-foreground',
            'bg-transparent',
            'border-transparent',
            'group-hover:text-button-ghost-foreground-hover'
          );
        }
        break;
    }

    return classes;
  }

  public getContentClasses(): string[] {
    const classes = [];

    if (this.size === 'L') {
      classes.push('typo-button-l');
    } else {
      classes.push('typo-button');
    }

    return classes;
  }

  public isIconContainerVisible(): boolean {
    return !!this.icon || this.loading;
  }
}
